<template>
	<div class="section ">
		<div class="container container--sm">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">Робочі простори</div>
					</div>

					<div class="section__main">
						<div class="space">
							<div class="space__list">
								<space-list></space-list>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


</template>

<script>
	import SpaceList from './SpaceList';

	export default {
		name: 'space-index',
		components:{
			SpaceList
		}
	};
</script>

<style scoped lang=scss>

</style>
